// slick slider
jQuery(function ($) {
    $(".slider").slick({
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        centerPadding: '20%',
        dots: true,
        arrows: false,
    });
});

// ナビゲーションアニメーション
$(".nav-btn").click(function () {//ボタンがクリックされたら
    $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
    $("#Hmenu").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
    $(".nav-btn-circle").toggleClass('circleactive');//丸背景にcircleactiveクラスを付与
});

$("#Hmenu a").click(function () {//ナビゲーションのリンクがクリックされたら
    $(".nav-btn").removeClass('active');//ボタンの activeクラスを除去し
    $("#Hmenu").removeClass('panelactive');//ナビゲーションのpanelactiveクラスを除去
    $(".nav-btn-circle").removeClass('circleactive');//丸背景のcircleactiveクラスを除去
});

//スクロールでハンバーガーメニューにクラスを付与
$(window).scroll(function () {
    if ($(window).scrollTop() > 250) {
        $('#NavBtn').addClass('fixed-menu');
    } else {
        $('#NavBtn').removeClass('fixed-menu');
    }
});