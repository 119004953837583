/* 概要 —————————————————
商品：LSP
用途：：無料トライアル
ディレクトリ：lsp1
パターン：コントロール
*/

// ▼ Bootstrap JS
import Button from 'bootstrap/js/dist/button';
import Collapse from 'bootstrap/js/dist/collapse';
import Modal from 'bootstrap/js/dist/modal';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Tooltip from 'bootstrap/js/dist/tooltip';

// ▼ その他のJS
import JS from './modules/js';

// ▼ ツールチップを初期化
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  //new Tooltip() でインスタンスを生成
  return new Tooltip(tooltipTriggerEl)
});
// ▲ツールチップを初期化

import './lsp1/style.scss';